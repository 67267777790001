@import '../../../../styles/colors.scss';
@import '../../../../styles/responsiveSize';

.navbar {
  padding: 20px 20px 20px 5px;

  .content {
    border-radius: 25px;
    background-color: $bkgNav;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 25px;
    p {
      margin: 0;
      margin-top: 5px;
    }
    h3 {
      margin: 0;
    }
    .actions {
      button {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
      }
    }
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      gap:10px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
