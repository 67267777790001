.card_community {
    p {
        color: black;
        margin: 0;
        text-align: center;
    }
    .card_content {
        border-radius: 8px;
    background: #D9D9D9;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 10px 10px;
    text-align: center;
        .title {
            font-size: 14px;
            font-weight: 500;
        }
        .sub {
            font-size: 12px;
        }
    }
} 