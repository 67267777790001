@import '../../styles/colors.scss';
.searchBar {
    input {
        padding: 10px;
        border-radius: 20px;
        width: 100%;
        background-color: #EEEEEE;
        border: 1px solid #B4B4B4;
    }
}
.date-data {
    p {
        color: $grayText;
        margin: 0;
    }
}

.MuiTableContainer-root {
    box-shadow: none!important;
}
.MuiTable-root {
    font-family: 'Montserrat', sans-serif!important;
}

.MuiTableBody-root {
    .MuiTableRow-root {
        font-family: 'Montserrat', sans-serif!important;
        .MuiTableCell-root {
            font-family: 'Montserrat', sans-serif;
            padding: 10px;
            border: none;
            &:first-child {
                border-top-left-radius: 25px!important;
                border-bottom-left-radius: 25px!important;
            }
            &:last-child {
                border-top-right-radius: 25px;
                border-bottom-right-radius: 25px;
            }
        }
    }
    .MuiTableRow-root:nth-child(odd) {
        background-color: #D9D9D9;
        border: none;
    }
}

.MuiTableHead-root {    
    .MuiTableRow-root {
        .MuiTableCell-root {
            font-family: 'Montserrat', sans-serif!important; 
        }
    }
}

.circle {
    height: 20px;
    width: 20px;
    border: 2px solid;
    border-radius: 50%;
    &.active {
        border-color: $green;   
    }
    border-color: $black;
}

.inputMain {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #CCC;
    padding: 10px;
    outline: none;
}
.labelMain {
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 10px;
    display: block;
}

.delete-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    .delete-modal-buttons {
        display: flex;
        gap: 10px;
    }
}