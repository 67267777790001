@import '../../styles/colors.scss';

.wrapper-content {
  h1 {
    color: $black;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h1, p {
      text-align: center;
    }
  }
}
