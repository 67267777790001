@import '../../styles/colors.scss';
@import '../../styles/responsiveSize';

.auth-template {
  position: relative;
  background-color: $white;
  height: 100vh;
  width: 100%;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include media('tablet') {
    height: auto;
    min-height: 100vh;
  }
  @include media('mobile') {
    height: auto;
    min-height: 100vh;
  }
  .content {
    min-height: auto;
    max-width: 350px;
    width: 100%;
    &[data-variation='large'] {
      @include media('tablet') {
        max-width: calc(100% - 16px);
        height: auto;
        flex-direction: column;
        background: linear-gradient(
          192deg,
          rgba(255, 255, 255, 0) 0%,
          rgb(0, 0, 0) 21%
        );
      }
    } 

    @include media('mobile') {
      max-width: calc(100% - 16px);
      height: auto;
      flex-direction: column;
      background: linear-gradient(
        192deg,
        rgba(255, 255, 255, 0) 0%,
        rgb(0, 0, 0) 21%
      );
    }

    .left,
    .right {
      border-radius: 16px;
      padding: 24px;
      @include media('tablet') {
        height: auto;
        width: 100% !important;
      }
      @include media('mobile') {
        height: auto;
        width: 100% !important;
      }
    }
    .left {
      width: 40%;
      max-width: 263px;
      &[data-variation='large'] {
        @include media('tablet') {
          width: 100%;
          max-width: calc(100% - 16px);
          min-width: calc(100% - 16px);
        }
      }
      @include media('mobile') {
        max-width: calc(100% - 16px);
         min-width: calc(100% - 16px);
      }
    }
    .right {
      background-color: white;
      width: 100%;
      color: $secondary;

      &[data-variation='large'] {
        min-width: 640px;
        max-width: 657px;
        @include media('tablet') {
          width: 100%;
          max-width: 100%;
          min-width: 100%;
        }
        @include media('mobile') {
          width: 100%;
          max-width: 100%;
          min-width: 100%;
        }
      }

      &[data-variation='small'] {
        max-width: 364px;
        @include media('mobile') {
          width: 100%;
          max-width: 100%;
        }
      }
      @include media('mobile') {
        max-width: 100%;
      }
    }
  }
}
